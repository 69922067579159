body,
html {
  margin: 0;
  --ui-font: system-ui, BlinkMacSystemFont, -apple-system, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
  font-family: var(--ui-font);
  -webkit-text-size-adjust: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  /*display: flex;*/
  height: 100vh;
  width: 100vw;
}
